<route lang="yaml">
name: home
meta:
  title: 主页
  icon: ant-design:home-twotone
</route>

<script setup lang="ts">
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import api from '@/api/modules/app'

interface ProfitInfo {
  profit_list: {
    account: string
    strategy: string
    value: number
    datetime: string
  }[]
}

interface ProfitLines {
  name: string
  data: [number, number][]
}

const totalProfitInfo = ref<ProfitInfo>({
  profit_list: [],
})

const profitLine = ref<ProfitLines[]>([])
const accountLine = ref<string[]>([])

const selectedAccount = ref('all')

async function getProfitList() {
  const res = await api.getProfitNew()
  totalProfitInfo.value = res.data
}

async function computProfit(account: string) {
  // 计算profit的累加值
  profitLine.value = []
  for (const index in Object.values(totalProfitInfo.value.profit_list)) {
    const profitInfo = totalProfitInfo.value.profit_list[index]
    // 添加用户
    const accountIndex = accountLine.value.findIndex(line => line === profitInfo.account)
    if (accountIndex === -1) {
      accountLine.value.push(profitInfo.account)
    }

    // 根据账户筛选数据
    if (account === 'all' || profitInfo.account === account) {
      // 计算总盈利曲线
      const totalIndex = profitLine.value.findIndex(line => line.name === 'total')
      if (totalIndex === -1) {
        profitLine.value.push({
          name: 'total',
          data: [[new Date(profitInfo.datetime).getTime(), profitInfo.value]],
        })
      }
      else {
        profitLine.value[totalIndex].data.push([
          new Date(profitInfo.datetime).getTime(),
          profitLine.value[totalIndex].data[profitLine.value[totalIndex].data.length - 1][1] + profitInfo.value,
        ])
      }

      // 查找是否已存在该策略的数据线
      const lineIndex = profitLine.value.findIndex(line => line.name === profitInfo.strategy)

      if (lineIndex === -1) {
        // 如果不存在则创建新的数据线
        profitLine.value.push({
          name: profitInfo.strategy,
          data: [[new Date(profitInfo.datetime).getTime(), profitInfo.value]],
        })
      }
      else {
        // 如果存在则添加数据点
        profitLine.value[lineIndex].data.push([
          new Date(profitInfo.datetime).getTime(),
          profitLine.value[lineIndex].data[profitLine.value[lineIndex].data.length - 1][1] + profitInfo.value,
        ])
      }
    }
  }
}

// 初始化曲线图配置

async function initChar() {
  const options = {
    chart: {
      type: 'spline',
      renderTo: 'profit-chart',
      height: 1200, // 增加图表高度
    },
    title: {
      text: '策略收益曲线',
      align: 'left',
    },
    subtitle: {
      text: '各策略收益走势',
      align: 'left',
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: '日期',
      },
    },
    yAxis: {
      title: {
        text: '收益',
      },
      tickAmount: 20, // 增加纵轴刻度数量
      minorTickInterval: 'auto', // 启用次要刻度
      minorGridLineDashStyle: 'dot', // 次要网格线样式
      gridLineWidth: 1, // 主网格线宽度
      minorGridLineWidth: 0.5, // 次要网格线宽度
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '{point.x:%Y-%m-%d}: {point.y:.2f}',
    },
    series: profitLine.value.map(line => ({
      ...line,
      type: 'spline',
      color: line.name === 'total' ? '#FF0000' : undefined,
    })),
  }

  const container = document.getElementById('profit-chart')
  if (container) {
    Highcharts.chart(container.id, options)
  }
}

async function initPage() {
  await getProfitList()
  await computProfit('all')
  initChar()
}

async function updateChart() {
  await computProfit(selectedAccount.value)
  initChar()
}

onMounted(() => {
  initPage()
})
</script>

<template>
  <div>
    <PageHeader>
      <template #title>
        <div class="flex items-center gap-4">
          欢迎来到策略平台
        </div>
      </template>
      <template #content>
        <div class="text-sm/6">
          <div>
            此页面会展示盈亏信息
          </div>
        </div>
      </template>
    </pageheader>

    <PageMain title="账号选择" style="margin: 10px 0;">
      <el-select v-model="selectedAccount" placeholder="Select" size="large" @change="updateChart">
        <el-option
          value="all"
          label="全部账户"
        />
        <el-option
          v-for="account in accountLine"
          :key="account"
          :value="account"
          :label="account"
        />
      </el-select>
    </PageMain>

    <PageMain title="策略收益" style="margin: 10px 0;">
      <div id="profit-chart" style="width: 100%; height: 1600px;" />
    </PageMain>
  </div>
</template>

<style lang="scss" scoped>
.text-emphasis {
  text-emphasis-style: "❤";
}

.ecology {
  --at-apply: flex-1 m-0;

  :deep(.title-container) {
    --at-apply: flex items-center justify-between flex-wrap gap-4;

    .title-info {
      --at-apply: flex items-center gap-4;

      img {
        --at-apply: block w-12 h-12;
      }

      h1 {
        --at-apply: m-0 text-2xl;
      }

      h2 {
        --at-apply: m-0 text-base text-stone-5 font-normal;
      }
    }
  }
}
</style>
