import '@/utils/system.copyright'
import HighchartsVue from 'highcharts-vue'

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

import Message from 'vue-m-message'
import 'vue-m-message/dist/style.css'

import 'overlayscrollbars/overlayscrollbars.css'

import { createAuth0 } from '@auth0/auth0-vue'
import App from './App.vue'
import pinia from './store'
import router from './router'
import ui from './ui-provider'

// 自定义指令
import directive from '@/utils/directive'

// 加载 svg 图标
import 'virtual:svg-icons-register'

// 加载 iconify 图标
import { downloadAndInstall } from '@/iconify'
import icons from '@/iconify/index.json'

import 'virtual:uno.css'

// 全局样式
import '@/assets/styles/globals.scss'

const app = createApp(App)

app.use(
  createAuth0({
    domain: 'dev-y8ltxc8vqwbsrv4n.jp.auth0.com',
    clientId: 'yPLYV0D2mhHxfeW91fWG2HC9MSjXDGCM',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://dev-y8ltxc8vqwbsrv4n.jp.auth0.com/api/v2/',
    },
  }),
)

app.use(FloatingVue, {
  distance: 12,
})
app.use(Message)
app.use(pinia)
app.use(router)
app.use(ui)
app.use(HighchartsVue)
directive(app)
if (icons.isOfflineUse) {
  for (const info of icons.collections) {
    downloadAndInstall(info)
  }
}

app.mount('#app')
